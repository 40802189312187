"use client";

import { useEffect } from "react";
import useMobileDetect from "./useMobileDetect";
import { appStoreLink, playStoreLink, webuddingLink } from "../constants";
import { init, track } from "@amplitude/marketing-analytics-browser";

const useUniversalLink = () => {
  const { isIOS, isAndroid } = useMobileDetect();

  useEffect(() => {
    const apiKey = "9ef42646dd99d9c5ef646673005f426e";
    // const apiKey = "d18b0294b0ae39ac900bf5323843d348";

    const getQueryParams = (): Record<string, string> => {
      const query = window.location.search.substring(1);
      return query
        .split("&")
        .reduce((params: Record<string, string>, param) => {
          const [key, value] = param.split("=");
          params[key] = decodeURIComponent(value);
          return params;
        }, {});
    };

    const queryParams = getQueryParams();
    const from = queryParams.from; // 'from' 값 가져오기 (또는 다른 값)

    init(apiKey).promise.finally(() => {
      track("page_view", {
        pageTitle: document.title,
        pageUrl: document.URL,
        referrer: document.referrer,
        service: "web",
        fromUrl: document.URL,
        from,
      }).promise.finally(() => {
        if (isIOS()) {
          window.location.href = appStoreLink;
        } else if (isAndroid()) {
          window.location.href = playStoreLink;
        } else {
          window.location.href =
            webuddingLink + window.location.pathname + window.location.search;
        }
      });
    });
  }, [isAndroid, isIOS]);
};

export default useUniversalLink;

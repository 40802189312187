import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        title: "WeBudding | Where your great journey begins",
        description:
          "A platform for people who constantly seeking to step up. You can get digital stationery for productivity and a partner you communicate with to grow together.",
        facebookType: "website",
        twitterCard: "summary_large_image",
        image: "https://cdn.webudding.com/webudding_main_og_image_eng.png",
      },
    },
    ko: {
      translation: {
        title: "위버딩 | 우리를 꽃피우는 곳",
        description:
          "성장하는 사람들을 위한 플랫폼. 굿노트 속지, 굿노트 다이어리와 스티커 등 디지털 문구를 구매하고, 함께 실천할 수 있는 파트너를 찾고 소통할 수 있어요.",
        facebookType: "website",
        twitterCard: "summary_large_image",
        image: "https://cdn.webudding.com/webudding_main_og_image_kor.png",
      },
    },
  },
  lng: "ko", // 기본 언어 설정
  fallbackLng: "ko",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

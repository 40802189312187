import UniversalLink from "./UniversalLink";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function Root() {
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("title")}</title>
        <meta property="og:title" content={t("title")} />
        <meta name="twitter:title" content={t("title")} />

        <meta name="description" content={t("description")} />
        <meta property="og:description" content={t("description")} />
        <meta name="twitter:description" content={t("description")} />

        <meta property="og:type" content={t("facebookType")} />
        <meta name="twitter:card" content={t("twitterCard")} />

        <meta property="og:image" content={t("image")} />
        <meta name="twitter:image" content={t("image")} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <UniversalLink />
    </div>
  );
}

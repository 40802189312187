const getMobileDetect = (userAgent: NavigatorID["userAgent"]) => {
  const isAndroid = () =>
    Boolean(userAgent.match(/Android|LG|SAMSUNG|Samsung/i));
  const isIOS = () =>
    Boolean(userAgent.match(/iPhone|iPad|iPod/i)) ||
    (userAgent.includes("Mac") && navigator.maxTouchPoints > 1);
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = () => Boolean(userAgent.match(/SSR/i));

  const isTablet = () =>
    Boolean(
      userAgent.match(/iPad/i) ||
        (userAgent.match(/Android/i) && !userAgent.match(/Mobile/i))
    );

  const isMobile = () =>
    Boolean(isAndroid() || isIOS() || isOpera() || isWindows());
  const isDesktop = () => Boolean(!isMobile() && !isSSR());

  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIOS,
    isTablet,
    isSSR,
  };
};

const useMobileDetect = () => {
  const userAgent =
    typeof navigator === "undefined" ? "SSR" : navigator.userAgent;

  return getMobileDetect(userAgent);
};

export default useMobileDetect;

import { appStoreLink, playStoreLink, webuddingLink } from "../constants";
import useMobileDetect from "../hooks/useMobileDetect";
import useUniversalLink from "../hooks/useUniversalLink";
import { useLocation } from "react-router-dom";

export default function UniversalLink() {
  useUniversalLink();

  const location = useLocation();
  const { isIOS, isAndroid } = useMobileDetect();
  const asPath = location.pathname + location.search;

  const handleApp = () => {
    let url = webuddingLink + asPath;
    if (isIOS()) {
      url = appStoreLink + asPath;
    } else if (isAndroid()) {
      url = playStoreLink + asPath;
    } else {
      url = webuddingLink + asPath;
    }

    window.location.href = url;
  };

  const handleWeb = () => {
    window.location.href = webuddingLink + asPath;
  };

  return (
    <main className="flex flex-col items-center justify-center min-w-screen min-h-screen">
      <img
        src={"/images/webudding_logo.png"}
        alt={`avatar`}
        width={120}
        height={32}
      />

      <p className="font-[700] text-[24px]">색다른 기록의 시작!</p>
      <p className="font-[400] text-[16px] text-center text-[#454545] mt-[20px] mb-[40px]">
        디지털 문구의 모든것,
        <br />
        위버딩 앱에서 함께하세요.
      </p>
      <button
        className="w-full bg-[#FF4C6C] rounded-[10px] h-[48px] text-[16px] px-[16px] py-[18px] text-white font-bold max-w-[320px] flex justify-center items-center"
        onClick={handleApp}
      >
        위버딩 앱으로 보기
      </button>
      <button
        className="w-full text-[14px] text-[#9e9e9e] font-regular max-w-[320px] underline mt-[14px] mb-[134px]"
        onClick={handleWeb}
      >
        모바일 웹으로 볼게요
      </button>

      <img
        src="https://cdn.webudding.com/commerce/images/apps_image.png"
        alt={`avatar`}
        width={340}
        height={240}
      />
    </main>
  );
}
